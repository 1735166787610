<template>
  <!-- 动态 -->
  <div class="Dynamic">
    <nav-bar title="动态" />
    <!-- 发布动态 -->
    <div class="release" @click="$router.push({name: 'release', query: $route.query})">
      <img src="@/assets/img/fabu.png" alt="">
      <span>发布动态</span>
    </div>
    <div class="wrap" @scroll="tobottom">
      <!-- 动态 -->
      <div class="dynamic">
        <div class="list">
          <div class="box" v-for="(item,index) in dongtai" :key="index" @click="toDetail(item.id)">
            <!-- 发布内容 -->
            <div class="top" @click.self="ipt_show = false">
              <div class="user" @click="ipt_show = false">
                <div class="name" @click="ipt_show = false">
                  <img :src="item.avatar == 'http://admin.wczstp.com' || item.avatar == '' ? require('@/assets/img/noface.png'):item.avatar" alt="" @click="ipt_show = false">
                  <span @click="ipt_show = false">{{item.user_name}}</span>
                </div>
                <div class="time" @click="ipt_show = false">{{item.create_time}}</div>
              </div>
              <div class="info">
                <div class="text" @click="ipt_show = false">{{item.content}}</div>
                <div class="imgs" @click="ipt_show = false">
                  <div v-for="(i,d) in item.imgs" :key="d" :style="{'height': height > 1000 ? '250px':'100px'}"><img :src="i" alt="" @click.stop="big_src = i;big_show = true"></div>
                </div>
              </div>
              <div class="comment">
                <img src="@/assets/img/like.png" alt="" @click="zan(item.id,$event)" v-if="item.is_like != 1" id="like">
                <img src="@/assets/img/like_select.png" alt="" v-else>
                <span>{{item.give_num == 0 ? '':item.give_num}}</span>
                <img src="@/assets/img/comment.png" alt="" @click.stop="comment(item.id)" style="width: 20px;height: 20px">
              </div>
              <!-- 评论 -->
              <div class="bottom">
                <div v-for="(j,d) in item.comments_list" :key="d" @click="ipt_show = false">
                  <span class="name">{{j.user_name}}：</span>
                  <span class="text">{{j.content}}</span>
                </div>
              </div>
            </div>
            <div class="more" @click="toDetail1(item.id)" v-if="item.comments_num != 0">
              <span>共{{item.comments_num}}条回复</span>
              <span>点击查看<i></i></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <div class="ipt" v-show="ipt_show"><input type="text" name="" id="" placeholder="输入你想要发布的内容吧~" v-model="text"><span @click="send">发送</span></div>
      <!-- 放大图片 -->
      <div class="big_img" @click="big_show = false" v-if="big_show">
        <img :src="big_src" alt="">
      </div>
    </div>
    <!-- 提示 -->
  </div>
</template>

<script>
import { NavBar } from 'vant'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      headerName: '动态',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      ipt_show: false, // 评论框显示
      dongtai: [], // 动态列表
      text: '', // 评论输入框内容
      textid: '', // 动态id
      height: '',
      big_src: '',
      big_show: false,
      page: 1,
      unset: false,
      nodata: false,
    }
  },
  mounted () {
    console.log('mounted')
    this.height = document.documentElement.clientHeight;
    // 动态初始数据
    this.getdongtai()
  },
  methods: {
    // 详情
    toDetail (id) {
      this.ipt_show = false;
      this.$router.push({ path: '/dynamic_detail?id='+id, query: this.$route.query })
    },
    // 评论
    comment (id) {
      this.textid = id;
      this.ipt_show = true;
    },
    // 动态数据列表
    getdongtai () {
      this.$post('/culture/getdynamicList',{page: this.page},(res)=>{
        if (res.data.code == 1) {
          this.dongtai = res.data.data;
          for (let i = 0; i < this.dongtai.length; i++) {
            if (this.dongtai[i].give_num >= 100000) {
              this.dongtai[i].give_num = JSON.stringify(this.dongtai[i].give_num.slice(0,2)+'w');
            } else if (this.dongtai[i].give_num >= 10000) {
              this.dongtai[i].give_num = JSON.stringify(this.dongtai[i].give_num.slice(0,1)+'w');
            } else if (this.dongtai[i].give_num >= 1000) {
              this.dongtai[i].give_num = JSON.stringify(this.dongtai[i].give_num.slice(0,1)+'k');
            }
          }
          if (this.height > 1000) {
            $('.list').css({
              paddingBottom: '100px'
            })
          }
        } else {
          this.mgs = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 加载更多
    tobottom (e) {
      let scrollTop = e.target.scrollTop;
      let clientHeight = e.target.clientHeight;
      let scrollHeight = e.target.scrollHeight;
      let boolean = false;
      if (this.unset) {
        return;
      }
      if ((scrollTop + clientHeight) >= scrollHeight) {
        boolean = true;
        if (boolean) {
          this.ifload = true;
          this.page ++;
          this.$post('/culture/getdynamicList',{page: this.page},(res)=>{
            this.ifload = false;
            if (res.data.code == 1) {
              if (res.data.data.length == 0) {
                this.nodata = true;
                this.unset = true;
              }
              var arr = res.data.data;
              var newArr = this.dongtai.concat(arr);
              this.dongtai = newArr;
            } else {
              this.msg = res.data.msg;
              this.showAlert = true;
            }
          })
        } else {
          boolean = false
        }
      }
    },
    // 点赞
    zan (id,e) {
      $(e.currentTarget).attr('src',require('@/assets/img/like_select.png'))
      this.$post('/culture/dynamicGive',{did: id},(res)=>{
        if (res.data.code == 1) {
          for (let i = 0; i < this.dongtai.length; i++) {
            if (this.dongtai[i].id == id) {
              this.dongtai[i].give_num = res.data.data;
            }
          }
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    },
    // 评价发送
    send () {
      if (this.text == '') {
        this.msg = '请输入内容';
        this.showAlert = true;
        return;
      }
      this.$post('/culture/dynamicComment',{did: this.textid,pid: 0,content: this.text},(res)=>{
        if (res.data.code == 1) {
          this.msg = '发送成功';
          this.showAlert = true;
          this.ipt_show = false;
          this.text = '';
          this.getdongtai()
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
          this.ipt_show = false;
          this.text = '';
        }
      })
    },
    // 评论详情
    toDetail1 (id) {
      this.$router.push({name: 'comment_detail',query: {id}})
    }
  }
}
</script>

<style lang='scss' scoped>
.Dynamic {
  width: 100%;
  height: 100vh;
  .release {
    position: fixed;
    right: 0;
    bottom: 100px;
    width: 80px;
    z-index: 2;
    background: #AC0404;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    border-radius: 50px 0 0 50px;
    img {
      width: 30px;
      margin-left: 10px;
    }
    span {
      color: white;
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .wrap {
    // position: absolute;
    // top: 50px;
    width: 100%;
    height: 100%;
    background: #F2ECD4;
    padding-top: 10px;
    overflow-y: scroll;
    // 动态
    .dynamic {
      padding: 0 10px;
      padding-bottom: 50px;
      .list {
        width: 100%;
        .box {
          width: 100%;
          margin-bottom: 5px;
          .top {
            position: relative;
            padding: 10px;
            border-radius: 10px 10px 0 0;
            background: white;
            .user {
              display: flex;
              justify-content: space-between;
              .name {
                display: flex;
                align-items: center;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  margin-right: 5px;
                }
                span {
                  font-size: 14px;
                  color: #646464;
                }
              }
              .time {
                font-size: 12px;
                color: #646464;
              }
            }
            .info {
              width: 100%;
              .text {
                margin: 10px 0;
                font-size: 14px;
                font-weight: bold;
                word-break: break-all;
              }
              .imgs {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                div {
                  width: 33.33%;
                  height: 100px;
                  padding: 2px;
                  box-sizing: border-box;
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    margin-bottom: 4px;
                  }
                }
              }
            }
            .comment {
              width: 70px;
              display: flex;
              justify-content: space-between;
              float: right;
              margin-top: 10px;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-size: 14px;
                color: #ff5555;
              }
            }
          }
          // 评论
          .bottom {
            width: 100%;
            background: #eeeeee;
            margin-top: 40px;
            div {
              display: flex;
              padding: 10px;
              span {
                display: inline-block;
              }
              .name {
                font-size: 14px;
                color: #646464;
              }
              .text {
                width: 250px;
                font-size: 12px;
                color: #646464;
                word-break: break-all;
                margin-top: 2px;
              }
            }
          }
          .more {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            background: white;
            color: #646464;
            padding: 5px 20px;
            box-sizing: border-box;
            span {
              display: flex;
              align-items: center;
              i {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-right: 1px solid #999;
                border-bottom: 1px solid #999;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
    // 评论输入框
    .ipt {
      position: fixed;
      bottom: 47px;
      width: 100%;
      height: 60px;
      background: white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input {
        width: 70%;
        height: 50%;
        border: none;
        outline: none;
        background: #eeeeee;
        border-radius: 20px;
        padding-left: 20px;
        font-size: 15px;
      }
      input::-webkit-input-placeholder{
        color: #A2A2A2;
      }
      span {
        padding: 5px 10px;
        background: #AB0404;
        color: white;
        font-size: 14px;
        border-radius: 20px;
      }
    }
    // 放大图片
    .big_img {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background: rgba($color: #000000, $alpha: .7);
      img {
        width: 100%;
      }
    }
  }
}
</style>